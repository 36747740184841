.flexible-modal {
  position: absolute;
  z-index: 1000;
  /* border: 1px solid #ccc; */
  background: rgb(15 22 31 / 90%);
  color: #efefef;
}
.flexible-modal-mask {
  height: 0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:2px;
  border-bottom: solid 1px #333;
  border-right: solid 1px #333;
  width: 5px !important;
  height: 5px !important;
}
.flexible-modal-drag-area{
  background: transparent;
  cursor:move;
  height: 20px;
  position:absolute;
  left: 120px;
  right:0;
  top:0;
}

.flexible-modal-drag-area-left {
  background: transparent;
  width: 20px;
  cursor:move;
  position: absolute;
  left: 0;
  top: 20px;
}

.flexible-modal-drag-area-right {
  height: 0 !important;
}

.modal_container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
}
