.output {
    color: #ddd;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    max-height: 300px;
    overflow-y: auto;
}

.input {
    background-color: #263238;
    margin-left: 20px;
    max-height: 350px;
    overflow-y: auto;
}

.CodeMirror {
    border: 0.5px solid #333;
    border-top-left-radius: 10px 5px;
    /* height: auto; */
    /* height: -2px; */
    background-color: #2e3a40;
}

.cell-btn {
    padding: 0px;
    padding-right: 2px;
    padding-left: 2px;
    background-color: #263238;
    border-color: #263238;
    border-style: solid;
    border-radius: 2px;
}

.cell-btn:hover {
    background-color: #343d4d;
}

.cell-btn-active {
    padding: 0px;
    padding-right: 3px;
    padding-left: 2px;
    background-color: #151619;
    color: #ffffff;
  }
