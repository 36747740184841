html, body, #provableRoot, #app {
  background-color: #222832;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.cellkey, .colkey {
  color: rgb(119, 117, 117);
}

.colkey {
  padding-left: 2px;
}

.button {
  padding: 0px;
  background-color: #222832;
  border-color: #222832;   /*#3f4042;*/
}

.btn-clear {
  padding: 0px;
  padding-right: 2px;
  padding-left: 2px;
  background-color: #222832;
  border: 0px;
  font-size: 20px;
  color: rgb(63 80 111);
}

.btn-clear:hover {
  background-color: #343d4d;
}

.menu {
  position: absolute;
  left: 2px;
}

.prov-col {
  overflow-y: auto;
}

/* used by slides */
.center-stuff {
  text-align: center;
  margin: auto;
  display: block;
  width: 100%;
}

.center-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tools {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  padding: 2.5px 7px;
  background-color: #efefef;
  position: fixed;
  top: 0px;
  left: 120px;
  border-radius: 2px;
}

.btnns {
  width: 40px;
  height: 33px;
  background: #efefef;
  text-align: center;
}

.btnns-svg {
  display: block;
  margin: auto;
}
